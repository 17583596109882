<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.name"
                        name="name"
                        label="库位编号"
                        placeholder="库位编号"
                        :rules="[{ required: true, message: '请填写库位编号' }]"
                    />
                    <select-component
                        name="enterprise"
                        lable="所属企业"
                        :farr="all_enterprise"
                        :fvalue_name="details.enterprise_name"
                        @clk="confirm_enterprise"
                    ></select-component>
                    <select-component
                        name="storeroom"
                        lable="所属仓库"
                        ref="storeroom_id_ref"
                        :farr="all_storeroom"
                        :fvalue_name="details.storeroom_name"
                        @clk="confirm_storeroom"
                    ></select-component>
                    <select-component
                        name="region"
                        lable="所属库区"
                        :farr="all_region"
                        :fvalue_name="details.region"
                        @clk="confirm_region"
                    ></select-component>
                    <number-keyboard-component
                        name="area"
                        label="库位总面积"
                        extra_key="."
                        right_icon="㎡"
                        placeholder="请输入库位总面积"
                        :fvalue="details.area"
                        @on_input="confirm_area"
                    ></number-keyboard-component>
                    <number-keyboard-component
                        name="max_weight"
                        label="库位总容量"
                        extra_key="."
                        right_icon="吨"
                        placeholder="请输入库位总容量"
                        :fvalue="details.max_weight"
                        @on_input="confirm_max_weight"
                    ></number-keyboard-component>
                    <number-keyboard-component
                        name="max_tray_num"
                        label="最大托盘数"
                        extra_key="."
                        right_icon="个"
                        placeholder="请输入最大托盘数"
                        :fvalue="details.max_tray_num"
                        @on_input="confirm_max_tray_num"
                    ></number-keyboard-component>
                    <van-field name="radio" label="库位类型">
                        <template #input>
                            <van-radio-group v-model="details.type" direction="horizontal" @change="radio_change">
                                <van-radio :name="0">仓储</van-radio>
                                <van-radio :name="1">仓租</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <select-component
                        v-if="details.type"
                        name="client"
                        lable="租用客户"
                        :farr="all_client"
                        :fvalue_name="details.client_name"
                        @clk="confirm_client"
                    ></select-component>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="库位编号" :value="details.name" />
                <van-cell title="所属企业" :value="details.enterprise_name" />
                <van-cell title="所属仓库" :value="details.storeroom_name" />
                <van-cell title="所属库区" :value="details.region" />
                <van-cell title="库位总面积" :value="details.area"><template #right-icon><span>㎡</span></template></van-cell>
                <van-cell title="库位总容量" :value="details.max_weight"><template #right-icon><span>吨</span></template></van-cell>
                <van-cell title="已使用容量" :value="details|yiyong_weight"><template #right-icon><span>吨</span></template></van-cell>
                <van-cell title="剩余容量" :value="details|shengyu_weight"><template #right-icon><span>吨</span></template></van-cell>
                <van-cell title="最大托盘数" :value="details.max_tray_num"><template #right-icon><span>个</span></template></van-cell>
                <van-cell title="已用托盘数" :value="details.used_tray_num"><template #right-icon><span>个</span></template></van-cell>
                <van-cell title="剩余托盘数" :value="details|shengyu_tray_num"><template #right-icon><span>个</span></template></van-cell>
                <van-cell title="库位类型" :value="['仓储','仓租'][details.type]" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="this.$_has('修改仓库')" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="this.$_has('删除仓库')" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { location_details_request,location_del_request,location_edit_request,location_add_request } from '@/network/WareHouse/location'
import { enterprise_list_request,storeroom_list_request,client_list_request } from '@/network/list.js'

import { fomatFloat } from '@/assets/js/common.js'

export default {
    name:'LocationDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                name: '',
                enterprise_id: '',
                storeroom_id: '',
                region: '',
                area: '',
                max_weight: '',
                max_tray_num: '',
                type:0,
                client_id: ''
            },
            edit: false,
            all_enterprise: [],
            all_storeroom: [],
            all_region: [
                {id:'A',name:'A'},
                {id:'B',name:'B'},
                {id:'C',name:'C'},
                {id:'D',name:'D'},
                {id:'E',name:'E'},
            ],
            all_client: []
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改库位' : '新增库位') : '库位详情'
        }
    },
    methods:{
        get_location_details() {
            this.$store.commit('true_loading')
            location_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                location_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
            this.details.storeroom_id = ''
            this.$refs.storeroom_id_ref.value_name = ''
            this.get_all_storeroom(e.id)
        },
        get_all_storeroom(id) {
            this.$store.commit('true_loading')
            storeroom_list_request({enterprise_id: id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_storeroom = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_storeroom(e) {
            this.details.storeroom_id = e.id
        },
        confirm_region(e) {
            this.details.region = e.id
        },
        confirm_area(e) {
            this.details.area = e
        },
        confirm_max_weight(e) {
            this.details.max_weight = e
        },
        confirm_max_tray_num(e) {
            this.details.max_tray_num = e
        },
        get_all_client() {
            this.$store.commit('true_loading')
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_client = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_client(e) {
            this.details.client_id = e.id
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                name: this.details.name,
                storeroom_id: this.details.storeroom_id,
                region: this.details.region,
                area: this.details.area,
                max_weight: this.details.max_weight,
                max_tray_num: this.details.max_tray_num,
                client_id: this.details.client_id
            }
            location_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_location_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                name: this.details.name,
                storeroom_id: this.details.storeroom_id,
                region: this.details.region,
                area: this.details.area,
                max_weight: this.details.max_weight,
                max_tray_num: this.details.max_tray_num,
                client_id: this.details.client_id
            }
            
            location_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_location_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        back_details() {
            this.get_location_details()
            this.edit = false
        },
        radio_change(e) {
            this.details.client_id='';
        }
    },
    filters:{
        yiyong_weight(v) {
            if(v.used_weight) {
                return fomatFloat(v.used_weight/1000)
            }
            
        },
        shengyu_weight(v) {
            if(v.max_weight) {
                return fomatFloat(v.max_weight - v.used_weight/1000)
            }
        },
        shengyu_tray_num(v) {
            return v.max_tray_num - v.used_tray_num
        }
    },
    props:{},
    created(){
        this.get_all_enterprise()
        this.get_all_client()

        this.id = this.$route.query.id;
        if(this.id) {
            this.get_location_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>